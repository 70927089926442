import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js';
import GUI from 'lil-gui';

import obj from '/json/sample.json' assert { type: 'json' };
import obj_1 from '/json/sample_1.json' assert { type: 'json' };
import obj_2 from '/json/colors.json' assert { type: 'json' };
import hdrFile from '/hdr/MR_INT-001_NaturalStudio_NAD-orig.hdr?url';
import ssTexture from '/textures/stainless-steel-lacanche-sample.jpg?url';
import blackTexture from '/textures/black-plain-concrete-textured.jpg?url';
import brassCapTexture from '/textures/texture_1.png?url';
import cherryRedTexture from '/textures/cherry-red-lacanche-colour-sample.jpg?url';
import backTexture from '/textures/istockphoto-174797487-2048x2048.jpg?url';

const gui = new GUI();
gui.close();
// gui.hide();
let dirLightHelper;
let dirLight_1Helper;
let envMap;

var MODEL_PATH = 'sully_2200_81.glb';
var Right_Cupboard_knob = 0;
var RO2_knobs = 0;
var Left_Cupboard_knob = 0;
var LO2_knobs = 0;

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

// ####################################################################################################################
// {"id" : 1,"name" : "stainless_steel","color" : "FFFFFF","metalness" : 0.9,"roughness" : 0.1},
var model_type_0 = 'classic';

// ####################################################################################################################

// Range
// if (urlParams.has('range')) {
//   const url_range = urlParams.get('range');
//   for (let i = 0; i < obj_1.range.length; i++) {
//     if (url_range == obj_1.range[i].range_id) {
//       MODEL_PATH = obj_1.range[i].range_model_id;
//     }
//   }
// }

// ####################################################################################################################

// Top
var top = 'T_3';
if (urlParams.has('top')) {
  const url_top = urlParams.get('top');
  for (let i = 0; i < obj_1.top.length; i++) {
    if (url_top == obj_1.top[i].top_id) {
      top = obj_1.top[i].top_model_id;
    }
  }
}

// ####################################################################################################################

// Left Options 2
var lo2 = 'LO2_SSW';
if (urlParams.has('lo2')) {
  const url_lo2 = urlParams.get('lo2');
  for (let i = 0; i < obj_1.options.length; i++) {
    if (url_lo2 == obj_1.options[i].o_id) {
      lo2 = 'LO2_' + obj_1.options[i].o_model_id;
    }
  }
}

// ####################################################################################################################

// Left Options
var lo = 'LO_SSW';
if (urlParams.has('lo')) {
  const url_lo = urlParams.get('lo');
  for (let i = 0; i < obj_1.options.length; i++) {
    if (url_lo == obj_1.options[i].o_id) {
      lo = 'LO_' + obj_1.options[i].o_model_id;
    }
  }
}

// ####################################################################################################################

// Right Options
var ro = 'RO_SSW';
if (urlParams.has('ro')) {
  const url_ro = urlParams.get('ro');
  for (let i = 0; i < obj_1.options.length; i++) {
    if (url_ro == obj_1.options[i].o_id) {
      ro = 'RO_' + obj_1.options[i].o_model_id;
    }
  }
}

// ####################################################################################################################

// Right Options 2
var ro2 = 'RO2_SSW';
if (urlParams.has('ro2')) {
  const url_ro2 = urlParams.get('ro2');
  for (let i = 0; i < obj_1.ro2.length; i++) {
    if (url_ro2 == obj_1.ro2[i].ro2_id) {
      ro2 = obj_1.ro2[i].ro2_model_id;
    }
  }
}

// ####################################################################################################################

// Left Cupboard
var lc = 'LC_SC';
if (urlParams.has('lc')) {
  const url_lc = urlParams.get('lc');
  for (let i = 0; i < obj_1.Cupboard_options.length; i++) {
    if (url_lc == obj_1.Cupboard_options[i].o_id) {
      lc = 'LC_' + obj_1.Cupboard_options[i].o_model_id;
    }
  }
}

// ####################################################################################################################

// Right Cupboard
var rc = 'RC_SC';
if (urlParams.has('rc')) {
  const url_rc = urlParams.get('rc');
  for (let i = 0; i < obj_1.Cupboard_options.length; i++) {
    if (url_rc == obj_1.Cupboard_options[i].o_id) {
      rc = 'RC_' + obj_1.Cupboard_options[i].o_model_id;
    }
  }
}

// ####################################################################################################################

// Body Color
var body_color = 'color';
if (urlParams.has('body_color')) {
  const url_body_color = urlParams.get('body_color');
  for (let i = 0; i < obj_1.Body_colors.length; i++) {
    if (url_body_color == obj_1.Body_colors[i].name) {
      body_color = obj_1.Body_colors[i].name;
    }
  }
}

// ####################################################################################################################

// trim Color
var trim_color = 'color';
if (urlParams.has('trim_color')) {
  const url_trim_color = urlParams.get('trim_color');
  for (let i = 0; i < obj_1.Trim_colors.length; i++) {
    if (url_trim_color == obj_1.Trim_colors[i].name) {
      trim_color = obj_1.Trim_colors[i].name;
    }
  }
}

// ####################################################################################################################

var theModel;

const canvas = document.querySelector('#c');

// Init the renderer
const renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
renderer.setPixelRatio(window.devicePixelRatio);
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure = 1;
renderer.shadowMap.enabled = true;

const BACKGROUND_COLOR = 0xffffff;
// Init the scene
const scene = new THREE.Scene();
// Set background
const white = new THREE.Color(BACKGROUND_COLOR);
scene.background = white;
// scene.fog = new THREE.Fog(BACKGROUND_COLOR, 20, 100);

// Add a camerra
var camera = new THREE.PerspectiveCamera(
  25,
  window.innerWidth / window.innerHeight,
  0.1,
  1000
);
// var camera = new THREE.OrthographicCamera();
camera.position.z = 8;
camera.position.x = 0;
camera.position.y = 2;
// camera.position.set( 0, 0, 75 );

// Initial material

const initialMaterial_TRIM = new THREE.MeshStandardMaterial({
  color: 0xffeda0,
  metalness: 1,
  roughness: 0,
});
const initialMaterial_logoboder = new THREE.MeshStandardMaterial({
  color: 0xe5cd9e,
  metalness: 0.9,
  roughness: 0.1,
});
const initialMaterial_logoplate = new THREE.MeshStandardMaterial({
  color: 0xffffff,
  metalness: 0.7,
  roughness: 0.1,
});

let txt_1 = new THREE.TextureLoader().load(ssTexture);
txt_1.repeat.set(1, 1, 1);
txt_1.wrapS = THREE.RepeatWrapping;
txt_1.wrapT = THREE.RepeatWrapping;
const new_mtl_01 = new THREE.MeshStandardMaterial({
  map: txt_1,
  metalness: 1,
  roughness: 0.1,
});

let txt_2 = new THREE.TextureLoader().load(blackTexture);
txt_2.repeat.set(1, 1, 1);
txt_2.wrapS = THREE.RepeatWrapping;
txt_2.wrapT = THREE.RepeatWrapping;
const new_mtl_02 = new THREE.MeshStandardMaterial({
  map: txt_2,
  metalness: 0.9,
  roughness: 0.5,
});

let txt_3 = new THREE.TextureLoader().load(brassCapTexture);
txt_3.repeat.set(1, 1, 1);
txt_3.wrapS = THREE.RepeatWrapping;
txt_3.wrapT = THREE.RepeatWrapping;
const new_mtl_03 = new THREE.MeshStandardMaterial({
  map: txt_3,
  metalness: 1,
  roughness: 0,
});

let txt_4 = new THREE.TextureLoader().load(cherryRedTexture);
txt_4.repeat.set(1, 1, 1);
txt_4.wrapS = THREE.RepeatWrapping;
txt_4.wrapT = THREE.RepeatWrapping;
const new_mtl_04 = new THREE.MeshStandardMaterial({
  map: txt_4,
  metalness: 0.9,
  roughness: 0.1,
});
var new_mtl = new_mtl_04;

let txt_5 = new THREE.TextureLoader().load(backTexture);
txt_5.repeat.set(1, 1, 1);
txt_5.wrapS = THREE.RepeatWrapping;
txt_5.wrapT = THREE.RepeatWrapping;
const new_mtl_21 = new THREE.MeshStandardMaterial({
  map: txt_5,
  metalness: 1,
  roughness: 0.1,
});

const new_mtl_7 = new THREE.MeshStandardMaterial({
  color: 0x808080,
  metalness: 0.5,
  roughness: 0.7,
});

const INITIAL_MAP = [
  { childID: 'lacanche', mtl: null },
  { childID: 'france', mtl: null },

  { childID: 'LOGO_BODER', mtl: initialMaterial_logoboder },
  { childID: 'LOGO_PLATE', mtl: initialMaterial_logoplate },
  { childID: 'MAIN_BODY', mtl: new_mtl_01 },
  { childID: 'O_DOOR', mtl: new_mtl_04 },
  { childID: 'BODY_BACK', mtl: new_mtl_21 },
  { childID: 'FRONT_KNOB_PLATE', mtl: new_mtl_04 },
  { childID: 'BOTTOM_PLATE', mtl: new_mtl_01 },
  { childID: 'C_ROD', mtl: new_mtl_01 },
  { childID: 'C_TRIM', mtl: initialMaterial_TRIM },
  { childID: 'C_FRONT_EXHAUST', mtl: new_mtl_01 },
  { childID: 'C_LO2_knob_1', mtl: initialMaterial_TRIM },
  { childID: 'C_LO2_knob_2', mtl: initialMaterial_TRIM },
  { childID: 'C_LO2_knob_3', mtl: initialMaterial_TRIM },
  { childID: 'C_LO_knob_1', mtl: initialMaterial_TRIM },
  { childID: 'C_LO_knob_2', mtl: initialMaterial_TRIM },
  { childID: 'C_OL_knob', mtl: initialMaterial_TRIM },
  { childID: 'C_OR_knob', mtl: initialMaterial_TRIM },
  { childID: 'C_RO2_knob_1', mtl: initialMaterial_TRIM },
  { childID: 'C_RO2_knob_2', mtl: initialMaterial_TRIM },
  { childID: 'C_RO2_knob_3', mtl: initialMaterial_TRIM },
  { childID: 'C_RO_knob_1', mtl: initialMaterial_TRIM },
  { childID: 'C_RO_knob_2', mtl: initialMaterial_TRIM },
  { childID: 'C_TL_knob_1', mtl: initialMaterial_TRIM },
  { childID: 'C_TL_knob_2', mtl: initialMaterial_TRIM },
  { childID: 'C_TR_knob_1', mtl: initialMaterial_TRIM },
  { childID: 'C_TR_knob_2', mtl: initialMaterial_TRIM },
  { childID: 'Dial_LO2_knob_1', mtl: null },
  { childID: 'Dial_LO2_knob_2', mtl: null },
  { childID: 'Dial_LO2_knob_3', mtl: null },
  { childID: 'Dial_LO_knob_1', mtl: null },
  { childID: 'Dial_LO_knob_2', mtl: null },
  { childID: 'Dial_OL_knob', mtl: null },
  { childID: 'Dial_OR_knob', mtl: null },
  { childID: 'Dial_RO2_knob_1', mtl: null },
  { childID: 'Dial_RO2_knob_2', mtl: null },
  { childID: 'Dial_RO2_knob_3', mtl: null },
  { childID: 'Dial_RO_knob_1', mtl: null },
  { childID: 'Dial_RO_knob_2', mtl: null },
  { childID: 'Dial_TL_knob_1', mtl: null },
  { childID: 'Dial_TL_knob_2', mtl: null },
  { childID: 'Dial_TR_knob_1', mtl: null },
  { childID: 'Dial_TR_knob_2', mtl: null },

  { childID: 'Dial_EP_RO2_knob_1', mtl: null },
  { childID: 'Dial_HC_RO2_knob_1', mtl: null },
  { childID: 'Dial_EP_RO2_knob_2', mtl: null },
  { childID: 'Dial_EP_RO2_knob_3', mtl: null },

  { childID: 'TOP_BASE_WD', mtl: new_mtl_01 },
  { childID: 'TOP_BASE_WOD', mtl: new_mtl_01 },
  { childID: 'LO2_CHEMINEE', mtl: new_mtl_01 },
  { childID: 'LO_CHEMINEE', mtl: new_mtl_01 },
  { childID: 'RO_CHEMINEE', mtl: new_mtl_01 },
  { childID: 'RO2_CHEMINEE', mtl: new_mtl_01 },
  { childID: 'Vented_Wall_Spacer', mtl: new_mtl_01 },
  { childID: 'RO2_11_15_G', mtl: new_mtl_02 },
  { childID: 'RO2_18_G', mtl: new_mtl_02 },
  { childID: 'RO2_SP_G', mtl: new_mtl_02 },
  { childID: 'RO2_11_B_B', mtl: null },
  { childID: 'RO2_15_B_B', mtl: null },
  { childID: 'RO2_18_B_B', mtl: null },
  { childID: 'RO2_11_B_T', mtl: new_mtl_03 },
  { childID: 'RO2_15_B_T', mtl: new_mtl_03 },
  { childID: 'RO2_18_B_T', mtl: new_mtl_03 },
  { childID: 'RO2_PP_D', mtl: new_mtl_01 },
  { childID: 'RO2_SP_D', mtl: new_mtl_01 },
  { childID: 'RO2_2IR_P', mtl: null },
  { childID: 'RO2_EP_T', mtl: new_mtl_01 },
  { childID: 'RO2_MC_T', mtl: new_mtl_01 },
  { childID: 'RO2_GG', mtl: null },
  { childID: 'RO2_SSP_GG_D', mtl: new_mtl_01 },
  { childID: 'RO_11_15_G', mtl: new_mtl_02 },
  { childID: 'RO_18_G', mtl: new_mtl_02 },
  { childID: 'RO_SP_G', mtl: new_mtl_02 },
  { childID: 'RO_11_B_B', mtl: null },
  { childID: 'RO_15_B_B', mtl: null },
  { childID: 'RO_18_B_B', mtl: null },
  { childID: 'RO_11_B_T', mtl: new_mtl_03 },
  { childID: 'RO_15_B_T', mtl: new_mtl_03 },
  { childID: 'RO_18_B_T', mtl: new_mtl_03 },
  { childID: 'RO_PP_D', mtl: new_mtl_01 },
  { childID: 'RO_SP_D', mtl: new_mtl_01 },
  { childID: 'RO_2IR_P', mtl: null },
  { childID: 'RO_EP_T', mtl: new_mtl_01 },
  { childID: 'RO_MC_T', mtl: new_mtl_01 },
  { childID: 'RO_GG', mtl: null },
  { childID: 'LO2_11_15_G', mtl: new_mtl_02 },
  { childID: 'LO2_18_G', mtl: new_mtl_02 },
  { childID: 'LO2_SP_G', mtl: new_mtl_02 },
  { childID: 'LO2_11_B_B', mtl: null },
  { childID: 'LO2_15_B_B', mtl: null },
  { childID: 'LO2_18_B_B', mtl: null },
  { childID: 'LO2_11_B_T', mtl: new_mtl_03 },
  { childID: 'LO2_15_B_T', mtl: new_mtl_03 },
  { childID: 'LO2_18_B_T', mtl: new_mtl_03 },
  { childID: 'LO2_PP_D', mtl: new_mtl_01 },
  { childID: 'LO2_SP_D', mtl: new_mtl_01 },
  { childID: 'LO2_2IR_P', mtl: null },
  { childID: 'LO2_EP_T', mtl: new_mtl_01 },
  { childID: 'LO2_MC_T', mtl: new_mtl_01 },
  { childID: 'LO2_GG', mtl: null },
  { childID: 'LO_LS_D', mtl: new_mtl_01 },
  { childID: 'LO_PL_D', mtl: new_mtl_01 },
  { childID: 'LO_PS_D', mtl: new_mtl_01 },
  { childID: 'LO_SL_D', mtl: new_mtl_01 },
  { childID: 'LO_SS_D', mtl: new_mtl_01 },
  { childID: 'LO_11_15_G', mtl: new_mtl_02 },
  { childID: 'LO_18_G', mtl: new_mtl_02 },
  { childID: 'LO_SP_G', mtl: new_mtl_02 },
  { childID: 'LO_11_B_B', mtl: null },
  { childID: 'LO_15_B_B', mtl: null },
  { childID: 'LO_18_B_B', mtl: null },
  { childID: 'LO_11_B_T', mtl: new_mtl_03 },
  { childID: 'LO_15_B_T', mtl: new_mtl_03 },
  { childID: 'LO_18_B_T', mtl: new_mtl_03 },
  { childID: 'LO_2IR_P', mtl: null },
  { childID: 'LO_EP_T', mtl: new_mtl_01 },
  { childID: 'LO_MC_T', mtl: new_mtl_01 },
  { childID: 'LO_GG', mtl: null },
  { childID: 'T_R_G', mtl: new_mtl_02 },
  { childID: 'T_B_B', mtl: null },
  { childID: 'T_B_T', mtl: new_mtl_03 },
  { childID: 'T_3_G', mtl: new_mtl_02 },
  { childID: 'T_SP_G', mtl: new_mtl_02 },
  { childID: 'T_3_B_B', mtl: new_mtl_7 },
  { childID: 'T_3_B_T', mtl: initialMaterial_TRIM },
  { childID: 'T_L_G', mtl: new_mtl_02 },
  { childID: 'T_4_B_B', mtl: null },
  { childID: 'T_4_B_T', mtl: new_mtl_03 },
];

new RGBELoader().load(hdrFile, function (texture) {
  texture.mapping = THREE.EquirectangularReflectionMapping;
  envMap = texture;
  scene.environment = texture;
  changeModel(MODEL_PATH);
});

// Init the object loader
var loader = new GLTFLoader();

function changeModel(MODEL_PATH) {
  loader.load(
    './models/' + MODEL_PATH,
    function (gltf) {
      // var theModel;
      theModel = gltf.scene;

      theModel.traverse((o) => {
        if (o.isMesh) {
          o.castShadow = true;
          o.receiveShadow = true;
        }
      });

      // Set the models initial scale
      theModel.scale.set(2, 2, 2);

      theModel.position.y = -1;
      theModel.position.x = 0;

      // Set initial textures
      for (let object of INITIAL_MAP) {
        initColor(theModel, object.childID, object.mtl);
      }

      // Add the model to the scene
      scene.add(theModel);

      Show_Hide_Test(theModel, '123');

      set_Model_Type(model_type_0);

      Show_Hide_TOP(theModel, top);

      Show_Hide_LO2(theModel, lo2);

      Show_Hide_LO(theModel, lo);

      Show_Hide_RO(theModel, ro);

      Show_Hide_RO2(theModel, ro2);

      set_Left_Cupboard(lc);

      set_Right_Cupboard(rc);

      set_color_type(body_color);

      set_trim_type(trim_color);

      // const knob3 = theModel.getObjectByName('C_LO2_knob_1');
      // const knobGrandParent = theModel.getObjectByName('6531942_ASM_C_knob');
      // const knobX = knob3.clone();
      // knobX.rotation.z = Math.PI;
      // knobGrandParent.add(knobX);
      // knobX.position.x = -1.8;
    },
    undefined,
    function (error) {
      console.error(error);
    }
  );

  initLight();

  // initFloor();
}

// Add the Lights to scene
function initLight() {
  // Add lights
  var hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 1);
  hemiLight.position.set(0, 10, 0);
  // Add hemisphere light to scene
  scene.add(hemiLight);

  var dirLight = new THREE.DirectionalLight(0xffffff, 1);
  dirLight.position.set(-3, 3, 4);
  // dirLight.castShadow = true;
  dirLight.shadow.mapSize = new THREE.Vector2(1024, 1024);
  // Add directional Light to scene
  scene.add(dirLight.target);
  scene.add(dirLight);

  dirLightHelper = new THREE.DirectionalLightHelper(dirLight, 1, 0xaaaa00);
  dirLightHelper.visible = false;
  scene.add(dirLightHelper);

  var dirLight_1 = new THREE.DirectionalLight(0xffffff, 1);
  dirLight_1.position.set(3, 3, 4);
  // dirLight_1.castShadow = true;
  dirLight_1.shadow.mapSize = new THREE.Vector2(1024 * 2, 1024 * 2);
  scene.add(dirLight_1);

  dirLight_1Helper = new THREE.DirectionalLightHelper(dirLight_1, 1, 0xaaaa00);
  dirLight_1Helper.visible = false;
  scene.add(dirLight_1Helper);

  const hemiLightFolder = gui.addFolder('Hemisphere Light');
  hemiLightFolder.add(hemiLight, 'intensity', 0, 5, 0.1);

  const leftLightFolder = gui.addFolder('Left Light');
  leftLightFolder.add(dirLight, 'intensity', 0, 5, 0.1);
  leftLightFolder.add(dirLight.position, 'x', -5, 0, 0.05).name('positionX');
  leftLightFolder.add(dirLight.position, 'y', 1, 5, 0.05).name('positionY');
  leftLightFolder.add(dirLight.position, 'z', -2, 8, 0.05).name('positionZ');
  leftLightFolder
    .add(dirLight.target.position, 'x', -3, 3, 0.1)
    .name('targetX');
  leftLightFolder
    .add(dirLight.target.position, 'y', -2, 2, 0.1)
    .name('targetY');
  leftLightFolder
    .add(dirLight.target.position, 'z', -6, 4, 0.1)
    .name('targetZ');
  leftLightFolder.add(dirLightHelper, 'visible').name('light-helper');

  const rightLightFolder = gui.addFolder('Right Light');
  rightLightFolder.add(dirLight_1, 'intensity', 0, 5, 0.1);
  rightLightFolder.add(dirLight_1.position, 'x', 0, 5, 0.05).name('positionX');
  rightLightFolder.add(dirLight_1.position, 'y', 1, 5, 0.05).name('positionY');
  rightLightFolder.add(dirLight_1.position, 'z', -2, 8, 0.05).name('positionZ');
  rightLightFolder
    .add(dirLight_1.target.position, 'x', -3, 3, 0.1)
    .name('targetX');
  rightLightFolder
    .add(dirLight_1.target.position, 'y', -2, 2, 0.1)
    .name('targetY');
  rightLightFolder
    .add(dirLight_1.target.position, 'z', -6, 4, 0.1)
    .name('targetZ');
  rightLightFolder.add(dirLight_1Helper, 'visible').name('light-helper');

  const props = {
    showEnvMap: false,
  };

  const envMapFolder = gui.addFolder('Environment Map');
  envMapFolder.add(props, 'showEnvMap').onChange((visible) => {
    if (visible) scene.background = envMap;
    else scene.background = white;
  });
}

// Add the Floor to scene
function initFloor() {
  // Floor
  var floorGeometry = new THREE.PlaneGeometry(5000, 5000, 1, 1);
  var floorMaterial = new THREE.MeshPhongMaterial({
    color: 0xffffff,
    shininess: 0,
  });
  var floor = new THREE.Mesh(floorGeometry, floorMaterial);
  floor.rotation.x = -0.5 * Math.PI;
  floor.position.y = -1;
  scene.add(floor);
}

// Function - Add the textures to the models
function initColor(parent, type, mtl) {
  parent.traverse((o) => {
    if (o.isMesh) {
      if (o.name.includes(type)) {
        if (mtl == null) {
          o.nameID = type;
        } else {
          o.material = mtl;
          o.nameID = type;
        }
      }
    }
  });
}

var controls = new OrbitControls(camera, renderer.domElement);
controls.minDistance = 1;
controls.maxDistance = 100;
// controls.maxPolarAngle = Math.PI / 2;
// controls.minPolarAngle = Math.PI / 3;
controls.enableDamping = true;
controls.enablePan = true;
controls.dampingFactor = 0.1;
controls.autoRotate = false; // Toggle this if you'd like the chair to automatically rotate
controls.autoRotateSpeed = 5; // 30

function animate() {
  controls.update();
  renderer.render(scene, camera);
  if (dirLightHelper) {
    dirLightHelper.update();
  }
  if (dirLight_1Helper) {
    dirLight_1Helper.update();
  }
  requestAnimationFrame(animate);
  if (resizeRendererToDisplaySize(renderer)) {
    const canvas = renderer.domElement;
    camera.aspect = canvas.clientWidth / canvas.clientHeight;
    camera.updateProjectionMatrix();
  }
}

animate();

/******************************************************************************/

const colors = obj_2.colors;
const tray = document.getElementById('jsTraySlide');

//Build Colors
function buildColors(_colors) {
  for (let [i, color] of colors.entries()) {
    let swatch = document.createElement('div');
    swatch.classList.add('traySwatch');
    if (color.texture) {
      swatch.style.backgroundImage = 'url(' + color.texture + ')';
    } else {
      swatch.style.background = '#' + color.color;
    }
    swatch.setAttribute('data-key', i);
    swatch.setAttribute('data-bs-toggle', 'tooltip');
    swatch.setAttribute('data-bs-placement', 'top');
    swatch.setAttribute('title', color.name);
    tray.append(swatch);
  }
}
buildColors(colors);

const swatches = document.querySelectorAll('.traySwatch');
for (const swatch of swatches) {
  swatch.addEventListener('click', selectSwatch);
}

var new_mtl;
function selectSwatch(e) {
  let color;
  color = colors[parseInt(e.target.dataset.key)];
  if (color.texture) {
    let txt = new THREE.TextureLoader().load(color.texture);
    txt.repeat.set(color.size[0], color.size[1], color.size[2]);
    txt.wrapS = THREE.RepeatWrapping;
    txt.wrapT = THREE.RepeatWrapping;
    new_mtl = new THREE.MeshStandardMaterial({
      map: txt,
      metalness: color.metalness ? color.metalness : 0.8,
      roughness: color.roughness ? color.roughness : 0.35,
    });
  } else {
    new_mtl = new THREE.MeshStandardMaterial({
      color: parseInt('0x' + color.color),
      metalness: color.metalness ? color.metalness : 0.8,
      roughness: color.roughness ? color.roughness : 0.35,
    });
  }
  setMaterial(theModel, 'O_DOOR', new_mtl);
  setMaterial(theModel, 'FRONT_KNOB_PLATE', new_mtl);
  setMaterial(theModel, 'MAIN_BODY', new_mtl);
}

/******************************************************************************/

const trimOption = document.getElementById('trim');
trimOption.addEventListener('change', function (e) {
  set_trim_type(e.target.value);
});

const topOption = document.getElementById('top');
topOption.addEventListener('change', function (e) {
  Show_Hide_TOP(theModel, e.target.value);
});

const farLeftOption = document.getElementById('leftOption2');
farLeftOption.addEventListener('change', function (e) {
  Show_Hide_LO2(theModel, e.target.value);
});

const leftOption = document.getElementById('leftOption');
leftOption.addEventListener('change', function (e) {
  Show_Hide_LO(theModel, e.target.value);
});

const rightOption = document.getElementById('rightOption');
rightOption.addEventListener('change', function (e) {
  Show_Hide_RO(theModel, e.target.value);
});

const farRightOption = document.getElementById('rightOption2');
farRightOption.addEventListener('change', function (e) {
  Show_Hide_RO2(theModel, e.target.value);
});

const leftCupboardOption = document.getElementById('leftCupboard');
leftCupboardOption.addEventListener('change', function (e) {
  set_Left_Cupboard(e.target.value);
});

const rightCupboardOption = document.getElementById('rightCupboard');
rightCupboardOption.addEventListener('change', function (e) {
  set_Right_Cupboard(e.target.value);
});

/******************************************************************************/

function resizeRendererToDisplaySize(renderer) {
  const canvas = renderer.domElement;
  var width = window.innerWidth;
  var height = window.innerHeight;
  var canvasPixelWidth = canvas.width / window.devicePixelRatio;
  var canvasPixelHeight = canvas.height / window.devicePixelRatio;
  const needResize = canvasPixelWidth !== width || canvasPixelHeight !== height;
  if (needResize) {
    renderer.setSize(width, height, false);
  }
  return needResize;
}

var new_mtl_dial_name;
var new_mtl_dial_pro;
function set_color_type(var_color) {
  for (let i = 0; i < obj_2.colors.length; i++) {
    if (var_color == obj_2.colors[i].name) {
      var_color = obj_2.colors[i].name;
      // for (let j = 0; j < obj_1.Body_colors.length; j++) {
      //   // debugger;
      //   if(var_color == obj_1.Body_colors[j]){
      //     new_mtl_dial_name = obj_1.Body_colors[j].Dial_name
      //     // debugger;
      //     for (let k = 0; k < obj_2.Dial_colors.length; k++) {
      //       // debugger;
      //       if (new_mtl_dial_name == obj_2.Dial_colors[k].name) {
      //         new_mtl_dial_pro = new THREE.MeshStandardMaterial({
      //           color: parseInt('0x' + obj_2.Dial_colors[k].color),
      //           metalness: obj_2.Dial_colors[k].metalness ? obj_2.Dial_colors[k].metalness : 0.8,
      //           roughness: obj_2.Dial_colors[k].roughness ? obj_2.Dial_colors[k].roughness : 0.35
      //         });
      //         // break;
      //       }
      //     }
      //     // break;
      //   }
      // }
      if (obj_2.colors[i].texture) {
        let txt = new THREE.TextureLoader().load(obj_2.colors[i].texture);
        txt.repeat.set(
          obj_2.colors[i].size[0],
          obj_2.colors[i].size[1],
          obj_2.colors[i].size[2]
        );
        txt.wrapS = THREE.RepeatWrapping;
        txt.wrapT = THREE.RepeatWrapping;
        new_mtl = new THREE.MeshStandardMaterial({
          map: txt,
          metalness: obj_2.colors[i].metalness
            ? obj_2.colors[i].metalness
            : 0.8,
          roughness: obj_2.colors[i].roughness
            ? obj_2.colors[i].roughness
            : 0.35,
        });
      } else {
        new_mtl = new THREE.MeshStandardMaterial({
          color: parseInt('0x' + obj_2.colors[i].color),
          metalness: obj_2.colors[i].metalness
            ? obj_2.colors[i].metalness
            : 0.8,
          roughness: obj_2.colors[i].roughness
            ? obj_2.colors[i].roughness
            : 0.35,
        });
      }
      setMaterial(theModel, 'O_DOOR', new_mtl);
      setMaterial(theModel, 'FRONT_KNOB_PLATE', new_mtl);
      setMaterial(theModel, 'MAIN_BODY', new_mtl);

      break;
    }
  }
}
var new_mtl;
var new_mtl_1;
function set_trim_type(var_trim) {
  for (let i = 0; i < obj_2.trim_colors.length; i++) {
    if (var_trim == obj_2.trim_colors[i].name) {
      if (obj_2.trim_colors[i].texture) {
        let txt = new THREE.TextureLoader().load(obj_2.trim_colors[i].texture);
        txt.repeat.set(
          obj_2.trim_colors[i].size[0],
          obj_2.trim_colors[i].size[1],
          obj_2.trim_colors[i].size[2]
        );
        txt.wrapS = THREE.RepeatWrapping;
        txt.wrapT = THREE.RepeatWrapping;
        new_mtl_1 = new THREE.MeshStandardMaterial({
          map: txt,
          metalness: obj_2.trim_colors[i].metalness
            ? obj_2.trim_colors[i].metalness
            : 0.9,
          roughness: obj_2.trim_colors[i].roughness
            ? obj_2.trim_colors[i].roughness
            : 0.1,
        });
      } else {
        new_mtl_1 = new THREE.MeshStandardMaterial({
          color: parseInt('0x' + obj_2.trim_colors[i].color),
          metalness: obj_2.trim_colors[i].metalness
            ? obj_2.trim_colors[i].metalness
            : 0.9,
          roughness: obj_2.trim_colors[i].roughness
            ? obj_2.trim_colors[i].roughness
            : 0.1,
        });
      }
      setMaterial(theModel, 'C_TRIM', new_mtl_1);
      for (let i = 0; i < obj_1.TRIM.length; i++) {
        setMaterial(theModel, obj_1.TRIM[i].id, new_mtl_1);
      }
    }
  }
}

// Wraps the material to the model Part
function setMaterial(parent, type, mtl) {
  parent.traverse((o) => {
    if (o.isMesh && o.nameID != null) {
      if (o.nameID == type) {
        o.material = mtl;
      }
    }
  });
}

// ####################################################################################################################

var var_Model_Type = 'Classic';
var model_type = 'C_';

function set_Model_Type() {
  model_type = 'C_';
  Show_Hide_Classic(theModel);
  setMaterial(theModel, 'FRONT_KNOB_PLATE', new_mtl);
  setMaterial(theModel, 'MAIN_BODY', new_mtl);
}

function Show_Hide_Classic(parent) {
  parent.traverse((o) => {
    if (o.isMesh && o.nameID != null) {
      if (o.nameID == 'C_TRIM') {
        o.visible = true;
      } else if (o.nameID == 'C_ROD') {
        o.visible = true;
      } else if (o.nameID == 'C_FRONT_EXHAUST') {
        o.visible = true;
      }
    }
  });
  Show_Hide_LO2_Knobs(theModel);
  Show_Hide_RO2_Knobs(theModel);
  Show_Hide_LO_Knobs(theModel);
  Show_Hide_RO_Knobs(theModel);
}

// ####################################################################################################################

function Show_Hide_Test(parent, type) {
  parent.traverse((o) => {
    if (o.isMesh && o.nameID != null) {
      if (type == 'Test_2') {
        if (o.nameID == 'TABLE_BASE') {
          o.visible = true;
        } else if (o.nameID == 'base') {
          o.visible = false;
        }
      } else {
        if (o.nameID == 'TOP_BASE_WOD') {
          o.visible = false;
        } else if (o.nameID == 'LO2_CHEMINEE') {
          o.visible = true;
        } else if (o.nameID == 'LO_CHEMINEE') {
          o.visible = true;
        } else if (o.nameID == 'RO_CHEMINEE') {
          o.visible = true;
        } else if (o.nameID == 'RO2_CHEMINEE') {
          o.visible = true;
        } else if (o.nameID == 'Vented_Wall_Spacer') {
          o.visible = true;
        } else if (o.nameID == 'LO_LS_D') {
          o.visible = false;
        } else if (o.nameID == 'LO_PS_D') {
          o.visible = false;
        } else if (o.nameID == 'LO_SL_D') {
          o.visible = false;
        } else if (o.nameID == 'LO_SS_D') {
          o.visible = false;
        } else if (o.nameID == 'RO2_GG') {
          o.visible = false;
        } else if (o.nameID == 'RO_GG') {
          o.visible = false;
        } else if (o.nameID == 'LO2_GG') {
          o.visible = false;
        } else if (o.nameID == 'LO_GG') {
          o.visible = false;
        } else if (o.nameID == 'RO2_SSP_GG_D') {
          o.visible = false;
        } else if (o.nameID == 'lacanche') {
          o.visible = true;
        } else if (o.nameID == 'france') {
          o.visible = true;
        } else if (o.nameID == 'Dial_EP_RO2_knob_1') {
          o.visible = false;
        } else if (o.nameID == 'Dial_HC_RO2_knob_1') {
          o.visible = false;
        } else if (o.nameID == 'Dial_EP_RO2_knob_2') {
          o.visible = false;
        } else if (o.nameID == 'Dial_EP_RO2_knob_3') {
          o.visible = false;
        }
      }
    }
  });
}

// ####################################################################################################################

//Left_Cupboard
var Left_Cupboard_knob = 0;

function set_Left_Cupboard(var_Left_Cupboard) {
  if (var_Left_Cupboard == 'LC_WC_SO') {
    Left_Cupboard_knob = 1;
  }
  Show_Hide_LO2_Knobs(theModel);
}

var LO2_knobs;
function Show_Hide_LO2(parent, type) {
  LO2_knobs = 0;
  parent.traverse((o) => {
    if (o.isMesh && o.nameID != null) {
      if (type == 'LO2_11_B') {
        LO2_knobs = 2;
        for (let i = 0; i < obj.LO2_11_B.length; i++) {
          if (o.nameID == obj.LO2_11_B[i].test_id) {
            o.visible = obj.LO2_11_B[i].test_visible;
          }
        }
      } else if (type == 'LO2_15_B') {
        LO2_knobs = 2;
        for (let i = 0; i < obj.LO2_15_B.length; i++) {
          if (o.nameID == obj.LO2_15_B[i].test_id) {
            o.visible = obj.LO2_15_B[i].test_visible;
          }
        }
      } else if (type == 'LO2_18_B') {
        LO2_knobs = 1;
        for (let i = 0; i < obj.LO2_18_B.length; i++) {
          if (o.nameID == obj.LO2_18_B[i].test_id) {
            o.visible = obj.LO2_18_B[i].test_visible;
          }
        }
      } else if (type == 'LO2_GG') {
        LO2_knobs = 1;
        for (let i = 0; i < obj.LO2_GG.length; i++) {
          if (o.nameID == obj.LO2_GG[i].test_id) {
            o.visible = obj.LO2_GG[i].test_visible;
          }
        }
      } else if (type == 'LO2_EP') {
        LO2_knobs = 1;
        for (let i = 0; i < obj.LO2_EP.length; i++) {
          if (o.nameID == obj.LO2_EP[i].test_id) {
            o.visible = obj.LO2_EP[i].test_visible;
          }
        }
      } else if (type == 'LO2_SP') {
        LO2_knobs = 1;
        for (let i = 0; i < obj.LO2_SP.length; i++) {
          if (o.nameID == obj.LO2_SP[i].test_id) {
            o.visible = obj.LO2_SP[i].test_visible;
          }
        }
      } else if (type == 'LO2_MC') {
        LO2_knobs = 1;
        for (let i = 0; i < obj.LO2_MC.length; i++) {
          if (o.nameID == obj.LO2_MC[i].test_id) {
            o.visible = obj.LO2_MC[i].test_visible;
          }
        }
      } else if (type == 'LO2_2IR') {
        LO2_knobs = 2;
        for (let i = 0; i < obj.LO2_2IR.length; i++) {
          if (o.nameID == obj.LO2_2IR[i].test_id) {
            o.visible = obj.LO2_2IR[i].test_visible;
          }
        }
      } else if (type == 'LO2_SSW') {
        LO2_knobs = 0;
        for (let i = 0; i < obj.LO2_SSW.length; i++) {
          if (o.nameID == obj.LO2_SSW[i].test_id) {
            o.visible = obj.LO2_SSW[i].test_visible;
          }
        }
      } else {
        LO2_knobs = 0;
        for (let i = 0; i < obj.LO2_SSW.length; i++) {
          if (o.nameID == obj.LO2_SSW[i].test_id) {
            o.visible = obj.LO2_SSW[i].test_visible;
          }
        }
      }
    }
  });
  Show_Hide_LO2_Knobs(theModel);
}

var Total_knobs_LO2;
function Show_Hide_LO2_Knobs(parent) {
  Total_knobs_LO2 = Left_Cupboard_knob + LO2_knobs;

  parent.traverse((o) => {
    if (o.isMesh && o.nameID != null) {
      if (Total_knobs_LO2 == 0) {
        if (
          o.nameID == model_type + 'LO2_knob_1' ||
          o.nameID == 'Dial_' + 'LO2_knob_1'
        ) {
          o.visible = false;
        } else if (
          o.nameID == model_type + 'LO2_knob_2' ||
          o.nameID == 'Dial_' + 'LO2_knob_2'
        ) {
          o.visible = false;
        } else if (
          o.nameID == model_type + 'LO2_knob_3' ||
          o.nameID == 'Dial_' + 'LO2_knob_3'
        ) {
          o.visible = false;
        }
      } else if (Total_knobs_LO2 == 1) {
        if (
          o.nameID == model_type + 'LO2_knob_1' ||
          o.nameID == 'Dial_' + 'LO2_knob_1'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'LO2_knob_2' ||
          o.nameID == 'Dial_' + 'LO2_knob_2'
        ) {
          o.visible = false;
        } else if (
          o.nameID == model_type + 'LO2_knob_3' ||
          o.nameID == 'Dial_' + 'LO2_knob_3'
        ) {
          o.visible = false;
        }
      } else if (Total_knobs_LO2 == 2) {
        if (
          o.nameID == model_type + 'LO2_knob_1' ||
          o.nameID == 'Dial_' + 'LO2_knob_1'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'LO2_knob_2' ||
          o.nameID == 'Dial_' + 'LO2_knob_2'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'LO2_knob_3' ||
          o.nameID == 'Dial_' + 'LO2_knob_3'
        ) {
          o.visible = false;
        }
      } else if (Total_knobs_LO2 == 3) {
        if (
          o.nameID == model_type + 'LO2_knob_1' ||
          o.nameID == 'Dial_' + 'LO2_knob_1'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'LO2_knob_2' ||
          o.nameID == 'Dial_' + 'LO2_knob_2'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'LO2_knob_3' ||
          o.nameID == 'Dial_' + 'LO2_knob_3'
        ) {
          o.visible = true;
        }
      }
    }
  });
}

// ####################################################################################################################

//Right_Cupboard
var Right_Cupboard_knob = 0;

function set_Right_Cupboard(var_Right_Cupboard) {
  if (var_Right_Cupboard == 'RC_WC_SO') {
    Right_Cupboard_knob = 1;
  }
  Show_Hide_RO2_Knobs(theModel);
}

var RO2_knobs;
function Show_Hide_RO2(parent, type) {
  parent.traverse((o) => {
    if (o.isMesh && o.nameID != null) {
      if (type == 'RO2_11_B') {
        RO2_knobs = 2;
        for (let i = 0; i < obj.RO2_11_B.length; i++) {
          if (o.nameID == obj.RO2_11_B[i].test_id) {
            o.visible = obj.RO2_11_B[i].test_visible;
          }
        }
      } else if (type == 'RO2_15_B') {
        RO2_knobs = 2;
        for (let i = 0; i < obj.RO2_15_B.length; i++) {
          if (o.nameID == obj.RO2_15_B[i].test_id) {
            o.visible = obj.RO2_15_B[i].test_visible;
          }
        }
      } else if (type == 'RO2_18_B') {
        RO2_knobs = 1;
        for (let i = 0; i < obj.RO2_18_B.length; i++) {
          if (o.nameID == obj.RO2_18_B[i].test_id) {
            o.visible = obj.RO2_18_B[i].test_visible;
          }
        }
      } else if (type == 'RO2_GG') {
        RO2_knobs = 1;
        for (let i = 0; i < obj.RO2_GG.length; i++) {
          if (o.nameID == obj.RO2_GG[i].test_id) {
            o.visible = obj.RO2_GG[i].test_visible;
          }
        }
      } else if (type == 'RO2_EP') {
        RO2_knobs = 1;
        for (let i = 0; i < obj.RO2_EP.length; i++) {
          if (o.nameID == obj.RO2_EP[i].test_id) {
            o.visible = obj.RO2_EP[i].test_visible;
          }
        }
      } else if (type == 'RO2_SP') {
        RO2_knobs = 1;
        for (let i = 0; i < obj.RO2_SP.length; i++) {
          if (o.nameID == obj.RO2_SP[i].test_id) {
            o.visible = obj.RO2_SP[i].test_visible;
          }
        }
      } else if (type == 'RO2_MC') {
        RO2_knobs = 1;
        for (let i = 0; i < obj.RO2_MC.length; i++) {
          if (o.nameID == obj.RO2_MC[i].test_id) {
            o.visible = obj.RO2_MC[i].test_visible;
          }
        }
      } else if (type == 'RO2_2IR') {
        RO2_knobs = 2;
        for (let i = 0; i < obj.RO2_2IR.length; i++) {
          if (o.nameID == obj.RO2_2IR[i].test_id) {
            o.visible = obj.RO2_2IR[i].test_visible;
          }
        }
      } else if (type == 'RO2_SSW') {
        RO2_knobs = 0;
        for (let i = 0; i < obj.RO2_SSW.length; i++) {
          if (o.nameID == obj.RO2_SSW[i].test_id) {
            o.visible = obj.RO2_SSW[i].test_visible;
          }
        }
      } else {
        RO2_knobs = 0;
        for (let i = 0; i < obj.RO2_SSW.length; i++) {
          if (o.nameID == obj.RO2_SSW[i].test_id) {
            o.visible = obj.RO2_SSW[i].test_visible;
          }
        }
      }
    }
  });
  Show_Hide_RO2_Knobs(theModel);
}

//Total_knobs_RO2
var Total_knobs_RO2;
function Show_Hide_RO2_Knobs(parent) {
  Total_knobs_RO2 = Right_Cupboard_knob + RO2_knobs;
  parent.traverse((o) => {
    if (o.isMesh && o.nameID != null) {
      if (Total_knobs_RO2 == 0) {
        for (let i = 0; i < obj.Total_knobs_RO2[0].k0.length; i++) {
          if (o.nameID == model_type + obj.Total_knobs_RO2[0].k0[i].test_id) {
            o.visible = obj.Total_knobs_RO2[0].k0[i].test_visible;
          }
          if (o.nameID == 'Dial_' + obj.Total_knobs_RO2[0].k0[i].test_id) {
            o.visible = obj.Total_knobs_RO2[0].k0[i].test_visible;
          }
        }
      } else if (Total_knobs_RO2 == 1) {
        for (let i = 0; i < obj.Total_knobs_RO2[0].k1.length; i++) {
          if (o.nameID == model_type + obj.Total_knobs_RO2[0].k1[i].test_id) {
            o.visible = obj.Total_knobs_RO2[0].k1[i].test_visible;
          }
          if (o.nameID == 'Dial_' + obj.Total_knobs_RO2[0].k1[i].test_id) {
            o.visible = obj.Total_knobs_RO2[0].k1[i].test_visible;
          }
        }
      } else if (Total_knobs_RO2 == 2) {
        for (let i = 0; i < obj.Total_knobs_RO2[0].k2.length; i++) {
          if (o.nameID == model_type + obj.Total_knobs_RO2[0].k2[i].test_id) {
            o.visible = obj.Total_knobs_RO2[0].k2[i].test_visible;
          }
          if (o.nameID == 'Dial_' + obj.Total_knobs_RO2[0].k2[i].test_id) {
            o.visible = obj.Total_knobs_RO2[0].k2[i].test_visible;
          }
        }
      } else if (Total_knobs_RO2 == 3) {
        for (let i = 0; i < obj.Total_knobs_RO2[0].k3.length; i++) {
          if (o.nameID == model_type + obj.Total_knobs_RO2[0].k3[i].test_id) {
            o.visible = obj.Total_knobs_RO2[0].k3[i].test_visible;
          }
          if (o.nameID == 'Dial_' + obj.Total_knobs_RO2[0].k3[i].test_id) {
            o.visible = obj.Total_knobs_RO2[0].k3[i].test_visible;
          }
        }
      }
    }
  });
}

// ####################################################################################################################

var Show_TOP;
var TOP_knobs = 1;
function Show_Hide_TOP(parent, type) {
  Show_TOP = type;
  parent.traverse((o) => {
    if (o.isMesh && o.nameID != null) {
      if (type == 'T_3') {
        TOP_knobs = 1;
        for (let i = 0; i < obj.T_3.length; i++) {
          if (o.nameID == obj.T_3[i].test_id) {
            o.visible = obj.T_3[i].test_visible;
          }
        }
      } else if (type == 'T_4') {
        TOP_knobs = 2;
        for (let i = 0; i < obj.T_4.length; i++) {
          if (o.nameID == obj.T_4[i].test_id) {
            o.visible = obj.T_4[i].test_visible;
          }
        }
      } else if (type == 'T_S2') {
        TOP_knobs = 1;
        for (let i = 0; i < obj.T_S2.length; i++) {
          if (o.nameID == obj.T_S2[i].test_id) {
            o.visible = obj.T_S2[i].test_visible;
          }
        }
      } else {
        TOP_knobs = 1;
        for (let i = 0; i < obj.T_3.length; i++) {
          if (o.nameID == obj.T_3[i].test_id) {
            o.visible = obj.T_3[i].test_visible;
          }
        }
      }
    }
  });
  Show_Hide_LO_TOP_D(theModel);
  Show_Hide_LO_Knobs(theModel);
}

var Show_LO;
var LO_knobs = 0;
function Show_Hide_LO(parent, type) {
  parent.traverse((o) => {
    if (o.isMesh && o.nameID != null) {
      Show_LO = type;
      if (type == 'LO_11_B') {
        LO_knobs = 2;
        for (let i = 0; i < obj.LO_11_B.length; i++) {
          if (o.nameID == obj.LO_11_B[i].test_id) {
            o.visible = obj.LO_11_B[i].test_visible;
          }
        }
      } else if (type == 'LO_15_B') {
        LO_knobs = 2;
        for (let i = 0; i < obj.LO_15_B.length; i++) {
          if (o.nameID == obj.LO_15_B[i].test_id) {
            o.visible = obj.LO_15_B[i].test_visible;
          }
        }
      } else if (type == 'LO_18_B') {
        LO_knobs = 1;
        for (let i = 0; i < obj.LO_18_B.length; i++) {
          if (o.nameID == obj.LO_18_B[i].test_id) {
            o.visible = obj.LO_18_B[i].test_visible;
          }
        }
      } else if (type == 'LO_GG') {
        LO_knobs = 1;
        for (let i = 0; i < obj.LO_GG.length; i++) {
          if (o.nameID == obj.LO_GG[i].test_id) {
            o.visible = obj.LO_GG[i].test_visible;
          }
        }
      } else if (type == 'LO_EP') {
        LO_knobs = 1;
        for (let i = 0; i < obj.LO_EP.length; i++) {
          if (o.nameID == obj.LO_EP[i].test_id) {
            o.visible = obj.LO_EP[i].test_visible;
          }
        }
      } else if (type == 'LO_SP') {
        LO_knobs = 1;
        for (let i = 0; i < obj.LO_SP.length; i++) {
          if (o.nameID == obj.LO_SP[i].test_id) {
            o.visible = obj.LO_SP[i].test_visible;
          }
        }
      } else if (type == 'LO_MC') {
        LO_knobs = 1;
        for (let i = 0; i < obj.LO_MC.length; i++) {
          if (o.nameID == obj.LO_MC[i].test_id) {
            o.visible = obj.LO_MC[i].test_visible;
          }
        }
      } else if (type == 'LO_2IR') {
        LO_knobs = 2;
        for (let i = 0; i < obj.LO_2IR.length; i++) {
          if (o.nameID == obj.LO_2IR[i].test_id) {
            o.visible = obj.LO_2IR[i].test_visible;
          }
        }
      } else if (type == 'LO_SSW') {
        LO_knobs = 0;
        for (let i = 0; i < obj.LO_SSW.length; i++) {
          if (o.nameID == obj.LO_SSW[i].test_id) {
            o.visible = obj.LO_SSW[i].test_visible;
          }
        }
      } else {
        LO_knobs = 0;
        for (let i = 0; i < obj.LO_SSW.length; i++) {
          if (o.nameID == obj.LO_SSW[i].test_id) {
            o.visible = obj.LO_SSW[i].test_visible;
          }
        }
      }
    }
  });
  Show_Hide_LO_TOP_D(theModel);
  Show_Hide_LO_Knobs(theModel);
}

function Show_Hide_LO_TOP_D(parent) {
  parent.traverse((o) => {
    if (o.isMesh && o.nameID != null) {
      if (Show_TOP == 'T_3' || Show_TOP == 'T_S2') {
        if (o.nameID == 'LO_LS_D') {
          o.visible = false;
        } else if (o.nameID == 'LO_SS_D') {
          o.visible = false;
        } else if (o.nameID == 'LO_PS_D') {
          o.visible = false;
        }

        if (
          Show_LO == 'LO_GG' ||
          Show_LO == 'LO_EP' ||
          Show_LO == 'LO_2IR' ||
          Show_LO == 'LO_SSW'
        ) {
          if (o.nameID == 'LO_PL_D') {
            o.visible = true;
          } else if (o.nameID == 'LO_SL_D') {
            o.visible = false;
          }
        } else {
          if (o.nameID == 'LO_PL_D') {
            o.visible = false;
          } else if (o.nameID == 'LO_SL_D') {
            o.visible = true;
          }
        }
      } else if (Show_TOP == 'T_4') {
        if (o.nameID == 'LO_SL_D') {
          o.visible = false;
        } else if (o.nameID == 'LO_PL_D') {
          o.visible = false;
        }

        if (
          Show_LO == 'LO_GG' ||
          Show_LO == 'LO_EP' ||
          Show_LO == 'LO_2IR' ||
          Show_LO == 'LO_SSW'
        ) {
          if (o.nameID == 'LO_PS_D') {
            o.visible = true;
          } else if (o.nameID == 'LO_SS_D') {
            o.visible = false;
          } else if (o.nameID == 'LO_LS_D') {
            o.visible = false;
          }
        } else if (Show_LO == 'LO_11_B' || Show_LO == 'LO_15_B') {
          if (o.nameID == 'LO_PS_D') {
            o.visible = false;
          } else if (o.nameID == 'LO_SS_D') {
            o.visible = true;
          } else if (o.nameID == 'LO_LS_D') {
            o.visible = false;
          }
        } else if (
          Show_LO == 'LO_18_B' ||
          Show_LO == 'LO_SP' ||
          Show_LO == 'LO_MC'
        ) {
          if (o.nameID == 'LO_PS_D') {
            o.visible = false;
          } else if (o.nameID == 'LO_SS_D') {
            o.visible = false;
          } else if (o.nameID == 'LO_LS_D') {
            o.visible = true;
          }
        }
      }
    }
  });
}

var TOP_knobs;
var LO_knobs;
var Total_knobs_LO;
function Show_Hide_LO_Knobs(parent) {
  Total_knobs_LO = TOP_knobs + LO_knobs;
  parent.traverse((o) => {
    if (o.isMesh && o.nameID != null) {
      if (Total_knobs_LO == 1) {
        if (
          o.nameID == model_type + 'OL_knob' ||
          o.nameID == 'Dial_' + 'OL_knob'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'TL_knob_1' ||
          o.nameID == 'Dial_' + 'TL_knob_1'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'TL_knob_2' ||
          o.nameID == 'Dial_' + 'TL_knob_2'
        ) {
          o.visible = false;
        } else if (
          o.nameID == model_type + 'LO_knob_1' ||
          o.nameID == 'Dial_' + 'LO_knob_1'
        ) {
          o.visible = false;
        } else if (
          o.nameID == model_type + 'LO_knob_2' ||
          o.nameID == 'Dial_' + 'LO_knob_2'
        ) {
          o.visible = false;
        }
      } else if (Total_knobs_LO == 2) {
        if (
          o.nameID == model_type + 'OL_knob' ||
          o.nameID == 'Dial_' + 'OL_knob'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'TL_knob_1' ||
          o.nameID == 'Dial_' + 'TL_knob_1'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'TL_knob_2' ||
          o.nameID == 'Dial_' + 'TL_knob_2'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'LO_knob_1' ||
          o.nameID == 'Dial_' + 'LO_knob_1'
        ) {
          o.visible = false;
        } else if (
          o.nameID == model_type + 'LO_knob_2' ||
          o.nameID == 'Dial_' + 'LO_knob_2'
        ) {
          o.visible = false;
        }
      } else if (Total_knobs_LO == 3) {
        if (
          o.nameID == model_type + 'OL_knob' ||
          o.nameID == 'Dial_' + 'OL_knob'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'TL_knob_1' ||
          o.nameID == 'Dial_' + 'TL_knob_1'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'TL_knob_2' ||
          o.nameID == 'Dial_' + 'TL_knob_2'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'LO_knob_1' ||
          o.nameID == 'Dial_' + 'LO_knob_1'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'LO_knob_2' ||
          o.nameID == 'Dial_' + 'LO_knob_2'
        ) {
          o.visible = false;
        }
      } else if (Total_knobs_LO == 4) {
        if (
          o.nameID == model_type + 'OL_knob' ||
          o.nameID == 'Dial_' + 'OL_knob'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'TL_knob_1' ||
          o.nameID == 'Dial_' + 'TL_knob_1'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'TL_knob_2' ||
          o.nameID == 'Dial_' + 'TL_knob_2'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'LO_knob_1' ||
          o.nameID == 'Dial_' + 'LO_knob_1'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'LO_knob_2' ||
          o.nameID == 'Dial_' + 'LO_knob_2'
        ) {
          o.visible = true;
        }
      }
    }
  });
}

// ####################################################################################################################

var RO_knobs;
function Show_Hide_RO(parent, type) {
  parent.traverse((o) => {
    if (o.isMesh && o.nameID != null) {
      if (type == 'RO_11_B') {
        RO_knobs = 2;
        for (let i = 0; i < obj.RO_11_B.length; i++) {
          if (o.nameID == obj.RO_11_B[i].test_id) {
            o.visible = obj.RO_11_B[i].test_visible;
          }
        }
      } else if (type == 'RO_15_B') {
        RO_knobs = 2;
        for (let i = 0; i < obj.RO_15_B.length; i++) {
          if (o.nameID == obj.RO_15_B[i].test_id) {
            o.visible = obj.RO_15_B[i].test_visible;
          }
        }
      } else if (type == 'RO_18_B') {
        RO_knobs = 1;
        for (let i = 0; i < obj.RO_18_B.length; i++) {
          if (o.nameID == obj.RO_18_B[i].test_id) {
            o.visible = obj.RO_18_B[i].test_visible;
          }
        }
      } else if (type == 'RO_GG') {
        RO_knobs = 1;
        for (let i = 0; i < obj.RO_GG.length; i++) {
          if (o.nameID == obj.RO_GG[i].test_id) {
            o.visible = obj.RO_GG[i].test_visible;
          }
        }
      } else if (type == 'RO_EP') {
        RO_knobs = 1;
        for (let i = 0; i < obj.RO_EP.length; i++) {
          if (o.nameID == obj.RO_EP[i].test_id) {
            o.visible = obj.RO_EP[i].test_visible;
          }
        }
      } else if (type == 'RO_SP') {
        RO_knobs = 1;
        for (let i = 0; i < obj.RO_SP.length; i++) {
          if (o.nameID == obj.RO_SP[i].test_id) {
            o.visible = obj.RO_SP[i].test_visible;
          }
        }
      } else if (type == 'RO_MC') {
        RO_knobs = 1;
        for (let i = 0; i < obj.RO_MC.length; i++) {
          if (o.nameID == obj.RO_MC[i].test_id) {
            o.visible = obj.RO_MC[i].test_visible;
          }
        }
      } else if (type == 'RO_2IR') {
        RO_knobs = 2;
        for (let i = 0; i < obj.RO_2IR.length; i++) {
          if (o.nameID == obj.RO_2IR[i].test_id) {
            o.visible = obj.RO_2IR[i].test_visible;
          }
        }
      } else if (type == 'RO_SSW') {
        RO_knobs = 0;
        for (let i = 0; i < obj.RO_SSW.length; i++) {
          if (o.nameID == obj.RO_SSW[i].test_id) {
            o.visible = obj.RO_SSW[i].test_visible;
          }
        }
      } else {
        RO_knobs = 0;
        for (let i = 0; i < obj.RO_SSW.length; i++) {
          if (o.nameID == obj.RO_SSW[i].test_id) {
            o.visible = obj.RO_SSW[i].test_visible;
          }
        }
      }
    }
  });
  Show_Hide_RO_Knobs(theModel);
}

// Show_Hide_RO_Knobs
var Total_knobs_RO;
function Show_Hide_RO_Knobs(parent) {
  Total_knobs_RO = RO_knobs;
  parent.traverse((o) => {
    if (o.isMesh && o.nameID != null) {
      if (Total_knobs_RO == 0) {
        if (
          o.nameID == model_type + 'OR_knob' ||
          o.nameID == 'Dial_' + 'OR_knob'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'TR_knob_1' ||
          o.nameID == 'Dial_' + 'TR_knob_1'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'TR_knob_2' ||
          o.nameID == 'Dial_' + 'TR_knob_2'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'RO_knob_1' ||
          o.nameID == 'Dial_' + 'RO_knob_1'
        ) {
          o.visible = false;
        } else if (
          o.nameID == model_type + 'RO_knob_2' ||
          o.nameID == 'Dial_' + 'RO_knob_2'
        ) {
          o.visible = false;
        }
      } else if (Total_knobs_RO == 1) {
        if (o.nameID == model_type + 'OR_knob' || o.nameID == 'Dial_' + '') {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'TR_knob_1' ||
          o.nameID == 'Dial_' + 'TR_knob_1'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'TR_knob_2' ||
          o.nameID == 'Dial_' + 'TR_knob_2'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'RO_knob_1' ||
          o.nameID == 'Dial_' + 'RO_knob_1'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'RO_knob_2' ||
          o.nameID == 'Dial_' + 'RO_knob_2'
        ) {
          o.visible = false;
        }
      } else if (Total_knobs_RO == 2) {
        if (o.nameID == model_type + 'OR_knob' || o.nameID == 'Dial_' + '') {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'TR_knob_1' ||
          o.nameID == 'Dial_' + 'TR_knob_1'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'TR_knob_2' ||
          o.nameID == 'Dial_' + 'TR_knob_2'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'RO_knob_1' ||
          o.nameID == 'Dial_' + 'RO_knob_1'
        ) {
          o.visible = true;
        } else if (
          o.nameID == model_type + 'RO_knob_2' ||
          o.nameID == 'Dial_' + 'RO_knob_2'
        ) {
          o.visible = true;
        }
      }
    }
  });
}

// ####################################################################################################################
